@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

// class to apply the `gradient` property inside the `<Text />` component
.text-fill-color-transparent {
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
}

.dark {
  body {
    background-color: #110b1b;
  }

  ::-webkit-scrollbar-track {
    background-color: #110b1b;
  }
  ::-webkit-scrollbar-thumb {
    background: #202231;
  }
}

.light {
  body {
    background-color: #f0f6fa;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f6fa;
  }
  ::-webkit-scrollbar-thumb {
    background: #dce2e6;
  }
}
